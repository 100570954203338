import { Dropdown, Form, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import MultiRangeSlider from "multi-range-slider-react";

import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
// import { queryActions } from "../../utils/reactQueryActions";
import { formatNumberSystem, paginationOptions } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NewVendorModal from "../NewVendorModal";
import EditVendorModal from "./EditVendor";
import { FilterTwoIcon, FilterCollapseIcon } from "../Icons";
import MultiRangeSlider from "../MultiRangeSlider";
import { fetchActionsUtil } from "../../utils/helpers";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import {
  ApproveCheckIcon,
  EditIcon,
  ChartPieSliceIcon,
  GearIcon,
} from "../Icons";

export default function Vendors() {
  const { backendUrl, token } = useAuth();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showEditVendorModal, setShowEditVendorModal] = useState({
    open: false,
    id: "",
  });

  const [max, setMax] = useState();
  const [min, setMin] = useState();

  const [initialRanges, setInitialRanges] = useState({ max: 0, min: 0 });

  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);

  const initialFilterParams = {
    q: "",
    cert: "",
    job: "",
    maxRange: 0,
    minRange: 0,
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const getCustomer = async (debouncedqueryParams) => {
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/vendors?${queryString.stringify(
        debouncedqueryParams
      )}`,
      "GET",
      token
    );

    const vendorData = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-data`,
      "GET"
    );

    setInitialRanges({
      max: vendorData?.maxRange,
      min: vendorData?.minRange,
    });

    data.vendorData = vendorData;

    return data;
  };

  const { data = { vendors: [], vendorData: {} }, refetch } = useQuery(
    ["GET all the vendors", debouncedFilterParams],
    () => getCustomer(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const setSelectedVendor = (e) => {
    if (Object.entries(e).length > 0) {
      refetch();
    }
  };

  const setEditVendor = (e) => {
    if (e) {
      refetch();
    }
  };

  // const onSelected = (userId) => {
  //   const theSelected = [...selectedData];
  //   if (theSelected.length > 0) {
  //     const index = theSelected.findIndex((i) => i === userId);

  //     if (index >= 0) {
  //       theSelected.splice(index, 1);
  //       setSelectedData(theSelected);
  //     } else {
  //       setSelectedData((d) => [...d, userId]);
  //     }
  //   } else {
  //     setSelectedData((d) => [...d, userId]);
  //   }
  // };

  // const sendRFQ = () => {
  //   console.log(selectedData);
  // };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      maxRange: max,
      minRange: min,
    });
  }, [min, max]);

  const verifyVendorMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/verify-user/${payload}`,
        "GET",
        token
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ error, message }) => {
        console.log(error);
        toast.error(message);
      },
    }
  );

  const verifyVendor = async (id, name) => {
    if (!id) return toast.error("Vendor Id is needed");

    if (
      await ConfirmDialog({
        title: "Verify Vendor",
        description: `Are you sure you want to verify ${name}`,
      })
    ) {
      verifyVendorMutation.mutate(id);
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <div className="input-group mb-3">
                      <span className="input-group-text border-right-0 bg-white">
                        <MagnifyIcon />
                      </span>
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams?.q}
                        onChange={(e) => handleFilterParamsChange(e)}
                        placeholder="Search by name"
                      />
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Search By Status</b>
                      </Form.Label>
                      <select
                        className="form-select form-select-md"
                        name="status"
                        aria-label="Default select example"
                        onChange={(e) => handleFilterParamsChange(e)}
                        value={filterParams?.status}
                      >
                        <option value="">Select...</option>
                        {[
                          { label: "Verified", value: "true" },
                          { label: "Not Verified", value: "false" },
                        ].map(({ value, label }, i) => (
                          <option key={i} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Search by Certificate</b>
                      </Form.Label>
                      <select
                        className="form-select form-select-md"
                        name="cert"
                        aria-label="Default select example"
                        onChange={(e) => handleFilterParamsChange(e)}
                        value={filterParams?.cert}
                      >
                        <option value="">Select...</option>
                        {data?.vendorData?.vendorCert?.map((value, i) => (
                          <option key={i} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Search By Job</b>
                      </Form.Label>
                      <select
                        className="form-select form-select-md"
                        name="job"
                        aria-label="Default select example"
                        onChange={(e) => handleFilterParamsChange(e)}
                        value={filterParams?.job}
                      >
                        <option value="">Select...</option>
                        {data?.vendorData?.vendorJobs?.map((value, i) => (
                          <option key={i} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Price Range"
                        name="enablePriceRange"
                        type={"checkbox"}
                        id={`enablePriceRange`}
                        defaultChecked={JSON.parse(
                          filterParams?.enablePriceRange || false
                        )}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    {initialRanges.min > 0 || initialRanges.max > 0 ? (
                      <Form.Group className="my-3">
                        <Form.Label>
                          <b>Search By Range</b>
                        </Form.Label>
                        <MultiRangeSlider
                          min={initialRanges.min}
                          max={initialRanges.max}
                          onChange={(e) => {
                            setMax(e.max);
                            setMin(e.min);
                          }}
                          formatNumber={true}
                        />
                      </Form.Group>
                    ) : null}

                    {/* <hr className="mt-3 mb-4" /> */}
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="content-body">
              <header className="text-nowrap">
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}

                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-wrap gap-3">
                      <Button
                        onClick={() => refetch()}
                        variant=""
                        className="bg-light-blue border-0 text-primary"
                      >
                        <CachedIcon />
                      </Button>
                    </div>
                  </div>
                </h1>
                <div className="actions">
                  {/* {selectedData.length > 0 && (
                    <Button
                      disabled={selectedData.length > 0 ? false : true}
                      variant="primary mx-4"
                      onClick={sendRFQ}
                    >
                      Send RFQ
                    </Button>
                  )} */}

                  <Button
                    variant="primary"
                    onClick={() => setShowCreateNewCustomerModal(true)}
                  >
                    + Create New
                  </Button>
                </div>
              </header>

              <div className="px-4 pb-4">
                <Table borderless striped responsive className="product-table">
                  <thead className="sticky border-bottom">
                    <tr>
                      <th />
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Contact Name</th>
                      <th>Contact Title</th>
                      <th>Date Created</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Verified By</th>
                      <th>Phone</th>
                      <th>Vendor_ID</th>
                      <th>City</th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody className="blue-hover">
                    {data.vendors &&
                      data.vendors.map((el, index) => (
                        <tr key={index}>
                          {/* <td>
                            <input
                              type="checkbox"
                              onChange={() => onSelected(el.Vendor_ID)}
                              checked={selectedData.find(
                                (id) => id === el.Vendor_ID
                              )}
                            />
                          </td> */}{" "}
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  onClick={() =>
                                    verifyVendor(el.Vendor_ID, el.CompanyName)
                                  }
                                  as="button"
                                >
                                  <ApproveCheckIcon /> Verify Vendor
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    setShowEditVendorModal({
                                      open: true,
                                      id: el.Vendor_ID,
                                    })
                                  }
                                  as="button"
                                >
                                  <EditIcon /> Edit
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    to={`/reports/purchase-history?vendorNumber=${
                                      el.Vendor_ID
                                    }&NoDate=${true}&CompName=${
                                      el.CompanyName
                                    }`}
                                  >
                                    <ChartPieSliceIcon /> Vendor Supply History
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    to={`/vendor/vendors-ledger?customerId=${
                                      el.Vendor_ID
                                    }&NoDate=${true}&customerName=${
                                      el.CompanyName
                                    }`}
                                  >
                                    <GearIcon /> View Vendor Ledger
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>{" "}
                          <td className="text-nowrap">
                            {formatNumberSystem(
                              el?.numberPrefix,
                              el?.currentNumber
                            )}
                          </td>
                          <td>{el.CompanyName}</td>
                          <td>{el.ContactName}</td>
                          <td>{el.ContactTitle}</td>
                          <td>{el.DateCreated}</td>
                          <td>{el.Email}</td>
                          <td>
                            <span
                              className={
                                el?.verified === "true"
                                  ? "view_status_all px-2"
                                  : "view_status_partly px-2"
                              }
                            >
                              {el?.verified === "true"
                                ? "Verified"
                                : "Not Verified"}
                            </span>
                          </td>
                          <td>{el.verifiedBy}</td>
                          <td>{el.Phone}</td>
                          <td>{el.Vendor_ID}</td>
                          <td>{el.City}</td>
                          <td>{el.Address}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
                <div className="pagination_left d-flex gap-3 align-items-center">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>
        {showCreateNewCustomerModal && (
          <NewVendorModal
            showCreateNewVendorModal={showCreateNewCustomerModal}
            setShowCreateNewVendorModal={setShowCreateNewCustomerModal}
            setSelectedVendor={setSelectedVendor}
          />
        )}
        {showEditVendorModal && (
          <EditVendorModal
            setShowCreateNewVendorModal={setShowEditVendorModal}
            showCreateNewVendorModal={showEditVendorModal}
            setSelectedVendor={setEditVendor}
          />
        )}
      </main>
    </section>
  );
}
