import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import { CustomInput } from "../utils/CustomInput";
import Datetime from "react-datetime";
import moment from "moment";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";

const ReIssueItemModal = (props) => {
  const { backendUrl } = useAuth();

  // console.log(data);
  const saveWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/storage/reIssue-item`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        props.refetch();
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to reissue item");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      issueTo: "",
      receivingofficer: "",
      itemName: props?.activeItem?.Item_Name,
      issueDate: moment(),
      costPrice: props?.activeItem?.UnitCost,
      item: props?.activeItem,
      contracts: props?.activeItem?.contracts,
    },
    validationSchema: yup.object().shape({
      issueTo: yup.string().required("Choose Location"),
      receivingofficer: yup.string().required("Receiving Officer is required"),
      itemName: yup.string().required("itemName is required"),
      issueDate: yup.string().required("issueDate is required"),
      costPrice: yup.string().required("costPrice is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Issue Item",
          description: `Are you sure you want to Issue this item`,
        })
      ) {
        console.log(values);
        saveWarehouse.mutate(values);
      }
    },
  });
  //   console.log(props?.activeItem);
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Re Issue Item</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                x
              </div>
            </div>
            {/* <p className="text-muted">
              
            </p> */}
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Location</label>
              <div className="form-group">
                <CreatableSelect
                  isClearable
                  onChange={(selected) => {
                    formik.setFieldValue("issueTo", selected?.value);
                  }}
                  options={props?.locations}
                  value={props?.locations?.find(
                    (el) => el.value === formik.values.issueTo
                  )}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.issueTo}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Receiver</label>
              <div className="form-group">
                <Select
                  classNamePrefix={"form-select"}
                  isSearchable={true}
                  value={props.employees.find(
                    (el) => el.value === formik.values.receivingofficer
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("receivingofficer", value)
                  }
                  options={props.employees}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.receivingofficer}
                </span>
              </div>
            </div>
            {/*  */}

            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Contract/Job
              </label>
              <Select
                classNamePrefix={"form-select"}
                isSearchable={true}
                value={props.contracts.find(
                  (el) => el.value === formik.values.contracts
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("contracts", value)
                }
                options={props.contracts}
              />
            </div>

            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Iten Name</label>
              <div className="form-group">
                <CustomInput
                  name="itemName"
                  placeholder="Enter Item Name"
                  typeOfInput="text"
                  label="Item Name"
                  disabled
                  value={formik.values.itemName}
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.users}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Issue Date
              </label>
              <Datetime
                dateFormat="MMM DD, YYYY"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="issueDate"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.issueDate && !!formik.errors.issueDate
                      ? "is-invalid"
                      : ""
                  }`,

                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.issueDate}
                onChange={(date) => {
                  formik.setFieldValue("issueDate", date, true);
                }}
                onBlur={() => formik.setFieldTouched("issueDate", true)}
              />
              {formik.touched.issueDate && !!formik.errors.issueDate ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.issueDate}
                </span>
              ) : null}
            </div>

            {/*  */}

            {/* <div className="mb-3">
              <label htmlFor="qty" className="form-label">
                Cost Price
              </label>
              <CurrencyCustomInput
                name="costPrice"
                value={formik.values.costPrice}
                onValueChange={(value, name) =>
                  formik.setFieldValue(name, value)
                }
                placeholder="0.00"
                decimalsLimit={2}
              />
              {formik.touched.costPrice && !!formik.errors.costPrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.costPrice}
                </span>
              ) : null}
            </div> */}

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Send
        </button>
      </div>
      <ModalLoader show={saveWarehouse.isLoading} />
    </Modal>
  );
};

export default ReIssueItemModal;
