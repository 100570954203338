import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addSaleItem,
  convertFromBaseCurrency,
  convertToBaseCurrency,
  copyText,
  customerFullName,
  formatCurrencyToNumber,
  formatDate,
  maxTopPopperConfig,
  pcsToTons,
  printers,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import {
  Field,
  FieldArray,
  useFormik,
  FormikProvider,
  ErrorMessage,
} from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useScrollTop,
  useEffectOnce,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import SelectSaleRepDialog from "../SaleRepDialog";
import { first } from "lodash";
import { filter } from "lodash";
import InvoiceAndWaybillDialog from "../InvoiceAndWaybillDialog";
import moment from "moment";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { truncate } from "lodash";
import { currenciesOptions } from "../../utils/currencies";
import { compareAsc, startOfDay } from "date-fns";
import NewRequisitionModal from "../Requisition/NewRequisition";

export default function CreateIronRodInvoice() {
  useScrollTop();
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment Multiple",
      value: "Split Payment Multiple",
    },
  ];

  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.05,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];

  const saleTypes = Units;

  const [isLoading, setIsLoading] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { backendUrl } = useAuth();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const location = useLocation();

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const createRodInvoice = async (payload) => {
    if (payload.currency && payload.currency !== "NGN") {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }
    // console.log(payload);
    // return;

    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      // alert(JSON.stringify(response));
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  const openInvoiceModal = async (data) => {
    await InvoiceAndWaybillDialog({
      title: "Transaction posted successfully",
      // description: "...",
      TransactionID: data.TransactionID,
      thermalPrinter: data.thermalPrinter,
    });
  };

  const createRodInvoiceMutation = useMutation(
    (payload) => createRodInvoice(payload),
    {
      onSuccess: async ({ data, message }) => {
        toast.success(message);
        data.thermalPrinter = formik.values.printer === "Thermal Printer";

        /*  if (formik.values.printReciept) {
          window.open(
            `${backendUrl}/api/invoice/pdf/invoice/${data.TransactionID}?${
              thermalPrinter ? `thermalPrinter=${thermalPrinter}` : ""
            }`,
            "_blank",
            "noopener,noreferrer"
          );
        }

        if (formik.values.printWaybill) {
          window.open(
            `${backendUrl}/api/invoice/pdf/waybill/${data.TransactionID}?`,
            "_blank",
            "noopener,noreferrer"
          );
        } */
        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
        openInvoiceModal(data);
      },
      onError: ({ message }) => {
        const errorData = JSON.parse(message);
        if (errorData.displayType === "modal") {
          //  This  show  not mess  with  our flow
          ConfirmDialog({
            type: "danger",
            title: errorData.message,
            description: "",
            choice: false,
            summaryTitle: "Summary",
            summary: errorData.summary,
          });
        } else {
          toast.error(`Unable to perform action: ${errorData.message || ""}`);
        }
      },
    }
  );

  // permit
  const createRodPermit = async (payload) => {
    if (payload.currency && payload.currency !== "NGN") {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/create-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.setFieldValue("pendingTransaction", data.TransactionID);
        setLockedTableData([...tableData]);
        copyText(
          `${services.frontendUrl}/approval/details/${data.TransactionID}`
        );
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: appSettings.requireSalesRep ? "Credit" : "Cash",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      printer: appSettings.printerType,
      posCharge: "",
      loadingCharge: "",
      offloadingCharge: "",
      isBatchStandard: appSettings.isBatchStandard, // for server to use
      transportCharge: "",
      Sale_Rep: "",
      Sale_Rep_Name: "",
      shippingCost: 0,
      otherCharges: 0,

      // Split Payment Multiple - Amounts
      splitPaymentCardAmount: "",
      splitPaymentChequeAmount: "",
      splitPaymentDirectBankTransferAmount: "",

      // Banks
      splitPaymentCardBank: "",
      splitPaymentChequeBank: "",
      splitPaymentDirectBankTransferBank: "",

      // Numbers
      splitPaymentChequeNumber: "",

      // --------------
      splitPaymentCardArray: [
        {
          amount: "",
          bank: "",
          posCharge: "",
        },
      ],

      splitDirectBankTransferArray: [
        {
          amount: "",
          bank: "",
        },
      ],

      splitPaymentChequeArray: [
        {
          amount: "",
          bank: "",
          chequeNumber: "",
        },
      ],

      Bar_Code: "",
      currency: "NGN",
      linkedPaymentID: "",
      jobNumber: "",
      milestone: "",
      conversionAmount: generalSettings.dollarInBaseCurrency,
      poNumber: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);

      // Check if the user has not chosen bank ----------------------------------------------
      if (
        ["Credit/Debit Card", "Direct Bank Transfer"].includes(
          formik.values.PaymentType
        ) &&
        formik.values.BankName === ""
      ) {
        showSelectBankDialog();
        return;
      } else if (
        formik.values.PaymentType === "Cheque" &&
        formik.values.BankName === ""
      ) {
        showSelectBankDialog({
          hasChequeNumber: true,
        });
        return;
      }
      // --------------------------------------------------------------------------------
      /*   if (
        !(await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to disapprove",
        }))
      )
        return; */

      // validate
      values.summaryFromMultiplePayments = {};

      if (values.PaymentType === "Split Payment Multiple") {
        let hasError = false;
        for (const [i, v] of values.splitPaymentCardArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitPaymentCardArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }
          values.summaryFromMultiplePayments[
            `Credit/Debit Card (POS) - ${v.bank}${str}`
          ] = v.amount;
        }

        for (const [i, v] of values.splitDirectBankTransferArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitDirectBankTransferArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }
          values.summaryFromMultiplePayments[
            `Direct Bank Transfer - ${v.bank}${str}`
          ] = v.amount;
        }

        for (const [i, v] of values.splitPaymentChequeArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitPaymentChequeArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }

          if (v.amount && !v.chequeNumber) {
            formik.setFieldError(
              `splitPaymentChequeArray[${i}].chequeNumber`,
              "Please add the Cheque Number"
            );
            hasError = true;
          }

          values.summaryFromMultiplePayments[`Cheque - ${v.bank}${str}`] =
            v.amount;
        }

        if (hasError === true) return;
      }

      if (values.PaymentType === "Split Payment Multiple") {
        values.posCharge = formatCurrencyToNumber(totalPosCharge);
      }
      //console.log(values);
      //return;
      //---------------------------

      // sales rep version
      if (appSettings.requireSalesRep) {
        if (isEmpty(values.Sale_Rep)) {
          return toast.error("Please select a sales rep");
        }
      }

      const currentPaymentWeWantToLink = paymentsData?.payments.find(
        (el) => el.value === values.linkedPaymentID
      );
      if (!currentPaymentWeWantToLink && values.linkedPaymentID) {
        return toast.error(
          `Payment not found - ${formik.values.linkedPaymentID}`
        );
      }

      // set config for sale permissions
      if (
        appSettings.ignoreSalePermissions &&
        !currentPaymentWeWantToLink?.requiresApproval
      ) {
        // confirm first
        if (
          !(await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this transaction",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          }))
        ) {
          return;
        }

        return createRodInvoiceMutation.mutate({
          items: tableData,
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          isRod: true,
          // blunder
          amountPaid:
            values.PaymentType === "Split Payment"
              ? /*values.cashAmount*/ values.amountPaid
              : values.PaymentType === "Split Payment Multiple"
              ? totalCashFromMultiPaymentSplit
              : values.PaymentType === "Customer Account"
              ? 0
              : values.amountPaid,
        });
      }

      if (values.pendingTransaction) {
        // Get items that are not in the locked set
        const lockedTableBarcode = lockedTableData.map((el) => el.Bar_Code);
        const newTableRecords = tableData.filter(
          (el) => !lockedTableBarcode.includes(el.Bar_Code)
        );
        const oldTableRecords = tableData.filter((el) =>
          lockedTableBarcode.includes(el.Bar_Code)
        );
        if (newTableRecords.find((el) => el.requirePermission)) {
          if (
            await ConfirmDialog({
              description:
                "One or more added items requires COST PERMISSIONS and cannot be posted would you like to post it for confirmation?",
            })
          ) {
            // send to pending
            createRodPermitMutation.mutate({
              items: newTableRecords.map((el) => ({
                ...el,
                Quantity: el.Quantity,
              })),
              ...values,
              subTotal,
              discount,
              amountDue,
              profit,
              balance,
              amount,
              customer: selectedCustomer,
              taxValue,
              costOFSales,
            });
          }
          return;
        } else {
          // check if old data has been - if data from server Status: "Pending", local requirePermission: false
          if (
            oldTableRecords.find((el) => el.requirePermission) ||
            oldTableRecords.find((el) => el.Status === "Pending")
          ) {
            toast.info("Some items in this transaction still needs approval");
          } else {
            // confirm first
            if (
              !(await ConfirmDialog({
                title: "Post Transaction",
                description: "Are you sure, you want to make this transaction",
                summaryTitle: "Summary",
                summary: setupSummary(values),
                removeUnderscore: true,
              }))
            ) {
              return;
            }
            // submit to backend - create transaction
            createRodInvoiceMutation.mutate({
              items: tableData,
              ...values,
              subTotal,
              discount,
              amountDue,
              profit,
              balance,
              amount,
              customer: selectedCustomer,
              taxValue,
              costOFSales,
              isRod: true,
              // blunder
              amountPaid:
                values.PaymentType === "Split Payment"
                  ? /*values.cashAmount*/ values.amountPaid
                  : values.PaymentType === "Split Payment Multiple"
                  ? totalCashFromMultiPaymentSplit
                  : values.PaymentType === "Customer Account"
                  ? 0
                  : values.amountPaid,
            });
          }
          return;
        }
      }

      // -----------------------------------------------------------

      // check if permission is required
      if (
        tableData.find((el) => el.requirePermission) ||
        currentPaymentWeWantToLink.requiresApproval
      ) {
        if (
          await ConfirmDialog({
            description: currentPaymentWeWantToLink?.requiresApproval
              ? "Payment requires PERMISSION and cannot be posted would you like to post it for confirmation?"
              : "One or more items requires COST PERMISSIONS and cannot be posted would you like to post it for confirmation?",
          })
        ) {
          /* if (location.state?.job) {
            setShowNewRequisitionModal(true);
            return;
          } */

          // send to pending
          createRodPermitMutation.mutate({
            items: tableData.map((el) => ({
              ...el,
              Quantity: el.Quantity,
              requirePermission: true,
            })),
            ...values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
          });
        }
        return;
      } else {
        // confirm first
        if (
          !(await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this transaction",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          }))
        ) {
          return;
        }
        createRodInvoiceMutation.mutate({
          items: tableData,
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          isRod: true,
          // blunder
          amountPaid:
            values.PaymentType === "Split Payment"
              ? /*values.cashAmount*/ values.amountPaid
              : values.PaymentType === "Split Payment Multiple"
              ? totalCashFromMultiPaymentSplit
              : values.PaymentType === "Customer Account"
              ? 0
              : values.amountPaid,
        });
      }
    },
    onReset: () => {
      setTableData([]);
      paymentsDataQuery.refetch();
    },
  });

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank && bank?.bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffectOnce(() => {
    if (location.state && location.state?.customer) {
      handleSelectedCustomer(location.state?.customer);
    }
  });

  useEffectOnce(() => {
    if (location.state) {
      if (location.state?.jobItems) {
        populateTableFromItems(location.state?.jobItems);
      }
    }
  });

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    data.banks = data.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        value: el.BankName,
        label: el.BankName,
      }));
    return data;
  };

  const { data = { invoiceCat: [], banks: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const paymentFilter = useMemo(() => {
    /*   return selectedCustomer?.Cust_ID && selectedCustomer?.Cust_ID !== "000101"
      ? { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" }
      : { Remark: formik.values.ShipTo, Cust_ID: "" }; */
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID /* , formik.values.ShipTo */]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        /*     Remark, */
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval =
          compareAsc(
            new Date(startOfDay(new Date())),
            new Date(el.Post_Time)
          ) === 1;

        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${
            requiresApproval ? "- Requires Approval" : ""
          }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  };

  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID /*  || !!customerPaymentsFilter.Remark */,
    }
  );

  const populateTableFromItems = (items) => {
    //console.log(items);
    // convert qtys and clean
    if (items && items[0]?.baseCurrency !== "NGN") {
      items = items.map((item) =>
        convertFromBaseCurrency({
          data: item,
          conversionAmount: items[0]?.baseConversionAmount,
        })
      );
    }
    //

    items = items.map((el) => {
      // check if sales type  - old  records
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        //  old way -  user  must  sell  either  ton or  pcs not both
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        // new way for new records
        ...(el?.saleType ? { saleType: el?.saleType } : {}),
        //-----
        Product_Name: el.ProductName ? el.ProductName : el.Product_Name,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
        Size: el.Serial_Number,
      };
    });

    //  console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        // Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        otherCharges,
        /*  currency: */ baseCurrency = "NGN",
        taxType,
        ShipTo,
        linkedPaymentID,
        jobNumber,
        milestone,
        /*  conversionAmount:  */ baseConversionAmount = 0,
        poNumber,
      } = items[0];

      //   console.log(linkedPaymentID, ShipTo);

      formik.setValues({
        ...formik.values,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        // salesDate: Date_Log, // use current date
        ...(TransactionID ? { pendingTransaction: TransactionID } : {}),
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency: baseCurrency,
        taxType: taxType || "None",
        jobNumber,
        milestone,
        conversionAmount: baseConversionAmount,
        poNumber,
      });

      // This was not setting, had to delay
      setTimeout(() => {
        if (ShipTo) {
          formik.setFieldValue("ShipTo", ShipTo);
        }
        if (linkedPaymentID) {
          formik.setFieldValue("linkedPaymentID", linkedPaymentID);
        }
      }, 500);

      setTableData(items);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);

      if (appSettings.requireSalesRep) {
        getSaleReps({
          selectedCustomer: customer,
          invoiceCat: ProductName,
        });
      }

      handlePaymentMethod(
        linkedPaymentID
          ? "Customer Account"
          : jobNumber
          ? "Credit"
          : formik.values.PaymentType
      );
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "ShipTo",
      selectedCustomer && selectedCustomer?.LastName
        ? selectedCustomer?.LastName
        : defaultCustomer?.LastName
    );
  }, [selectedCustomer]);

  const handleSelectedPermit = (permit) => {
    setShowPermitModal(false);
    populateTableFromItems(permit.items);
  };

  const handleAddItem = (item) => {
    //  console.log(item);
    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      //  console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${
            firstItem.Product_Name
          }), `
        );
      }
    }

    if (isEmpty(tableData) && appSettings.requireSalesRep) {
      // get Sales Rep
      getSaleReps({
        selectedCustomer,
        invoiceCat: item.Product_Name,
      });
    }

    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    if (Number(item.quantityInStock) <= 0) {
      formik.setFieldValue("supplyNow", false);
      formik.setFieldValue("printWaybill", false);
    }

    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }
    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const getSaleReps = async ({ selectedCustomer, invoiceCat }) => {
    try {
      let response = await fetch(
        `${backendUrl}/api/customers/sales-rep?${queryString.stringify({
          Cust_ID: selectedCustomer.Cust_ID,
          Product: invoiceCat,
        })}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { customerReps = [] },
        } = await response.json();
        if (isEmpty(customerReps)) {
          getSaleRepDialog({
            selectedCustomer,
            invoiceCat,
          });
          return;
        } else {
          const foundSaleRep = first(customerReps);
          formik.setFieldValue("Sale_Rep", foundSaleRep.Rep_ID);
          formik.setFieldValue(
            "Sale_Rep_Name",
            customerFullName(foundSaleRep.employee)
          );
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get saleReps");
    } finally {
    }
  };

  /*  const handleSelectedItem = (item) => {
    if (Number(item.Quantity) < 1) return toast.error(`No item in stock`);
    const UnitPrice = currency(item.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();

    item = {
      ...item,
      Quantity: tonsToPcs(1, item.Item_Desc, itemMeasurements),
      quantityInStock: item.Quantity,
      UnitPrice,
      PriceSold: UnitPrice,
      SubTotal: UnitPrice,
      Warranty: false,
      Discount: 0,
      Profit: currency(UnitPrice, {
        symbol: "",
        separator: "",
      })
        .subtract(item.UnitCost)
        .format(),
      ironRodQuantity: 1,
      saleType: "tons",
      requirePermission: false,
      Serial_Number: item.Item_Desc,
    };
    handleAddItem(item);
    setShowItemsPopover(false);
  }; */

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map(
            (el) => el.Discount
            /*  currency(el.Discount, { symbol: "", separator: "" })
              .multiply(
                convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
              )
              .format() */
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount) // don't known -
          .format()
      : "0.00";
  }, [tableData, discount]);

  const totalPosCharge = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.posCharge)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(totalPosCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    totalPosCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    // add loading charges

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .add(chargesAfterTax)
          .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);

    // will be set before we get here
    // if (value !== "Split Payment Multiple") {
    // formik.setFieldValue("cashAmount", total);
    //}
    return total;
  }, [tableData, taxValue, formik.values.PaymentType, chargesAfterTax]);

  // other cash
  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const totalSplitPaymentCardAmount = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const totalSplitPaymentChequeAmount = useMemo(() => {
    return formik.values.splitPaymentChequeArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentChequeArray]);

  const totalSplitPaymentDirectBankTransferAmount = useMemo(() => {
    return formik.values.splitDirectBankTransferArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitDirectBankTransferArray]);

  //----------

  const totalCashFromMultiPaymentSplit = useMemo(() => {
    return currency(totalSplitPaymentCardAmount, {
      symbol: "",
      separator: "",
    })
      .add(totalSplitPaymentChequeAmount)
      .add(totalSplitPaymentDirectBankTransferAmount)
      .add(formik.values.cashAmount)
      .add(chargesAfterTax)
      .format();
  }, [
    totalSplitPaymentCardAmount,
    totalSplitPaymentChequeAmount,
    totalSplitPaymentDirectBankTransferAmount,
    formik.values.cashAmount,
    chargesAfterTax,
  ]);

  /*  const totalCashFromMultiPaymentSplit = useMemo(() => {
    return currency(formik.values.splitPaymentCardAmount, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.splitPaymentChequeAmount)
      .add(formik.values.splitPaymentDirectBankTransferAmount)
      .add(formik.values.cashAmount)
      .add(chargesAfterTax)
      .format();
  }, [
    formik.values.splitPaymentCardAmount,
    formik.values.splitPaymentChequeAmount,
    formik.values.splitPaymentDirectBankTransferAmount,
    formik.values.cashAmount,
    chargesAfterTax,
  ]); */

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.PaymentType === "Split Payment Multiple"
          ? totalCashFromMultiPaymentSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
    totalCashFromMultiPaymentSplit,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b).value,
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
    // clear pos charge
    if (value !== "Credit/Debit Card") {
      formik.setFieldValue("posCharge", 0);
    }

    if (value === "Split Payment" || value === "Split Payment Multiple") {
      formik.setFieldValue("cashAmount", amountDue);
    }
  };

  const handleSupplyNow = (value) => {
    // formik.setFieldValue("printReciept", value);
    formik.setFieldValue("printWaybill", false);
  };

  const getSaleRep = async () => {
    if (isEmpty(tableData)) {
      return toast.error("No item Selected");
    }
    const { Sale_Rep_Name, Sale_Rep } = await SelectSaleRepDialog({
      selectedCustomer,
      invoiceCat: formik.values.invoiceCat,
    });

    if (Sale_Rep) {
      formik.setFieldValue("Sale_Rep", Sale_Rep);
      formik.setFieldValue("Sale_Rep_Name", Sale_Rep_Name);
    }
  };

  const getSaleRepDialog = async ({ selectedCustomer, invoiceCat }) => {
    const { Sale_Rep_Name, Sale_Rep } = await SelectSaleRepDialog({
      selectedCustomer,
      invoiceCat,
    });

    if (Sale_Rep) {
      formik.setFieldValue("Sale_Rep", Sale_Rep);
      formik.setFieldValue("Sale_Rep_Name", Sale_Rep_Name);
    }
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    //console.log(!isEmpty(tableData), appSettings.requireSalesRep);
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      getSaleReps({
        selectedCustomer: customer,
        invoiceCat: formik.values.invoiceCat,
      });
    }

    // Reset  Selected  Data  that  may not be  theirs
    formik.setFieldValue("linkedPaymentID", "");
  };

  const setupSummary = (values) => {
    const data =
      values.PaymentType === "Split Payment Multiple"
        ? {
            "Amount Due": amountDue,
            Cash: values.cashAmount,
            ...values.summaryFromMultiplePayments,
            "Total Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            "Total Amount Paid": totalCashFromMultiPaymentSplit,
          }
        : values.PaymentType === "Split Payment"
        ? {
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            Cash: values.cashAmount,
            [values?.splitPaymentType]: values.amount,
          }
        : {
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            [`${values.PaymentType}${
              ["Credit/Debit Card", "Direct Bank Transfer", "Cheque"].includes(
                values.PaymentType
              )
                ? ` - ${values.BankName}`
                : ""
            }`]: values.amountPaid,
          };

    for (const key in data) {
      if (isEmpty(data[key])) {
        delete data[key];
      }
    }
    return data;
  };

  const getBatches = async (selectedItemFromPopover) => {
    //  check if  batch has been  fetched before
    /* const previouslySelectedItem = tableData.find(
      (el) => el.Bar_Code === selectedItemFromPopover.Bar_Code
    );
    if (previouslySelectedItem) {
    } */

    try {
      setIsLoading(true);

      let response = await fetch(
        `${backendUrl}/api/items/batches-for-sales/${
          selectedItemFromPopover.Bar_Code
        }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { batches = [], sizes = [], item = {} },
        } = await response.json();
        if (item) {
          quickAddItem({ ...selectedItemFromPopover, ...item, batches, sizes });
        } else {
          toast.error("Item not found");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load batches, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const quickAddItem = async (selectedItem) => {
    let Quantity = 0;
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === selectedItem.Bar_Code
    );

    if (foundIndex === -1) {
      Quantity = 1;
    } else {
      Quantity = Number(tableData[foundIndex].Quantity) + 1;
    }

    const data = await addSaleItem({
      selectedItem: {
        ...selectedItem,
        Quantity,
      },
      selectedCustomer,
      itemMeasurements,
    });

    //    console.log(data);

    handleAddItem(data);
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "₦";
  }, [formik.values.currency]);

  const handleLinkPayment = async (selected) => {
    const setShipTo = () => {
      //walk-In
      if (selectedCustomer?.Cust_ID === "000101") {
        formik.setFieldValue("ShipTo", selected.Remark);
      } else {
        formik.setFieldValue("ShipTo", customerFullName(selectedCustomer));
      }
      formik.setFieldValue("linkedPaymentID", selected?.value);

      if (selected?.value) {
        handlePaymentMethod("Customer Account");
      }
    };

    if (selected.requiresApproval) {
      if (
        await ConfirmDialog({
          description:
            "Payment requires PERMISSION and cannot be posted would you like to post it for confirmation?",
        })
      ) {
        setShipTo();
      }
    } else {
      setShipTo();
    }
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="Create Invoice"
        description="Create a new transaction invoice with your customer.."
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-md-flex content-holder rounded">
          <section
            /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
            className="item-details"
          >
            <div>
              <header>
                <h1>
                  Invoice{" "}
                  {formik.values.jobNumber
                    ? `-  ${formik.values.jobNumber}`
                    : ""}
                </h1>
              </header>
              <div className="actions">
                <div>
                  <h2>Item Details</h2>
                  <p>Add items in to the invoice table. </p>
                </div>
                <div className="d-flex gap-3">
                  <Form.Group>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select Currency"
                      isSearchable={false}
                      options={currenciesOptions}
                      value={currenciesOptions.find(
                        (el) => el.value === formik.values.currency
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("currency", value)
                      }
                      isDisabled={formik.values.jobNumber}
                    />
                  </Form.Group>

                  {formik.values.currency &&
                  formik.values.currency !== "NGN" ? (
                    <Form.Group>
                      <CurrencyCustomInput
                        currencySymbol={"Ex. Rate"}
                        name="conversionAmount"
                        value={formik.values.conversionAmount}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  ) : null}
                  {!formik.values.jobNumber && (
                    <>
                      <Form.Group className="">
                        <Form.Label className="d-none">Item Code</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className=""
                            type="text"
                            placeholder="Enter Item Code"
                            name="Bar_Code"
                            value={formik.values.Bar_Code}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.Bar_Code &&
                              !!formik.errors.Bar_Code
                            }
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                e.stopPropagation();
                                getBatches({ Bar_Code: e.target.value });
                              }
                            }}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={(item) => {
                                  formik.setFieldValue(
                                    "Bar_Code",
                                    item.Bar_Code
                                  );
                                  setShowItemsPopover(false);
                                  getBatches(item);
                                }}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.Bar_Code}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button
                        onClick={() => setShowItemSelectorModal(true)}
                        variant="outline-primary"
                        className="text-nowrap"
                      >
                        + Add Item
                      </Button>
                    </>
                  )}
                </div>
              </div>

              <div className="selected-data-area">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    striped
                    className="product-table  text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Size/Desc</th>
                        <th>Item Name</th>
                        <th>Price Sold</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>Barcode</th>
                        <th>Product name</th>
                        <th>Unit Price</th>
                        <th>Profit</th>
                        <th>Warranty</th>
                        <th>Warranty Duration</th>
                        <th>Overwrite officer</th>
                        <th>Cost</th>
                        <th>Type</th>
                        <th>Incentive</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((el, index) => (
                        <tr
                          key={index}
                          onClick={(e) => handleRowClick(e, index)}
                          className="p-cursor"
                        >
                          <td>
                            {!formik.values.jobNumber && (
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVeritcalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => setEditedItemIndex(index)}
                                  >
                                    <EditIcon />
                                    Edit Items
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    <DeleteIcon />
                                    Remove Item
                                  </Dropdown.Item>
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            )}
                          </td>
                          <td title={el.Serial_Number}>
                            {truncate(el.Serial_Number)}
                          </td>
                          <td>{el.Item_Name}</td>
                          <td>
                            {currency(el.PriceSold, { symbol: "" }).format()}
                          </td>
                          <td title={el.Quantity}>
                            {!["tons", "pieces"].includes(
                              lowerCase(el?.saleType || "")
                            )
                              ? currency(el.Quantity, {
                                  symbol: "",
                                  precision: 1,
                                })
                                  .format()
                                  .toString()
                                  .replace(".0", "")
                              : qtyFormatToString(
                                  qtyFormat(
                                    el?.Quantity,
                                    el?.Item_Desc,
                                    itemMeasurements
                                  ),
                                  true
                                )}
                          </td>
                          <td>
                            {currency(el.SubTotal, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Discount, { symbol: "" }).format()}
                          </td>
                          <td>{el.Bar_Code}</td>
                          <td>{el.Product_Name || el.ProductName}</td>
                          <td>
                            {currency(el.UnitPrice, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Profit, { symbol: "" }).format()}
                          </td>
                          <td>{el.Warranty}</td>
                          <td>{el.Warrant_Duration}</td>
                          <td>{"..."}</td>
                          <td>
                            {currency(Number(el.UnitCost), { symbol: "" })
                              .multiply(
                                convertQuantity(
                                  el.Quantity,
                                  el.Serial_Number,
                                  el.saleType
                                )
                              )
                              .format()}
                          </td>
                          <td>{el.Item_Type || "..."}</td>
                          <td>
                            {currency(el.Total_Incentive, {
                              symbol: "",
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                {/* <Popover
                  isOpen={showItemsPopover}
                  reposition={true}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <ItemsTable
                      hideItemsNotInStock={true}
                      handleSelectedItem={handleSelectedItem}
                    />
                  )}
                >
                  <button
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add an Item
                  </button>
                </Popover> */}

                {/*  No item  */}
                {isEmpty(tableData) ? (
                  <div className="no-item my-4">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected an item yet</h2>
                      <p>
                        You can click +Add Item Button to add an item to the
                        table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-end total-info">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                      {currency(subTotal, {
                        symbol: currencySymbol,
                      })
                        .add(chargesAfterTax)
                        .format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Discount</td>
                    <td>
                      {/*  <button className="btn text-primary p-0">
                        +Add Discount
                      </button> */}

                      {currency(discount, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Amount Due</td>
                    <td>
                      {currency(amountDue, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Balance</td>
                    <td>
                      {currency(balance, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(grandTotal, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className="customer">
            <h2>Customer</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <UserSolidIcon />
              </div>
              {selectedCustomer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{selectedCustomer?.LastName}</h3>
                    <p>{selectedCustomer.Cust_ID}</p>
                  </div>

                  <div>
                    {!formik.values.pendingTransaction && (
                      <Dropdown style={{ margin: 0 }}>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-light-blue text-primary"
                          bsPrefix="change"
                        >
                          Change
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className=""
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCustomerSelectorModal(true)}
                          >
                            Select Customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCreateNewCustomerModal(true)}
                          >
                            Create New Customer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>Select customer or create new customer.</p>

                  <div className="d-grid mt-4">
                    <Button
                      onClick={() => setShowCustomerSelectorModal(true)}
                      variant="outline-primary"
                    >
                      Select Customer
                    </Button>
                    <Button
                      onClick={() => setShowCreateNewCustomerModal(true)}
                      variant="outline-primary"
                    >
                      + Create New Customer
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <FormikProvider value={formik}>
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Form.Group className="form-mb align-items-center">
                  <Form.Label className="mb-1 text-nowrap fw-bold">
                    Ship To.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ship To"
                    name="ShipTo"
                    value={formik.values.ShipTo}
                    onChange={formik.handleChange}
                    /*   onKeyDown={(e) => {
                      if (
                        e.keyCode === 13 &&
                        generalSettings?.linkPaymentToInvoice
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        paymentsDataQuery.refetch();
                      }
                    }} */
                  />
                </Form.Group>

                {generalSettings?.linkPaymentToInvoice && (
                  <Form.Group className="form-mb align-items-center">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Link Payment to Invoice
                    </Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      options={paymentsData?.payments}
                      value={paymentsData?.payments.find(
                        (el) => el.value === formik.values.linkedPaymentID
                      )}
                      onChange={(selected) => {
                        handleLinkPayment(selected);
                      }}
                      isClearable={true}
                      isLoading={paymentsDataQuery.isFetching}
                      isDisabled={formik.values.pendingTransaction}
                    />
                  </Form.Group>
                )}
                <>
                  {formik.values.milestone && (
                    <Form.Group className="form-mb align-items-center">
                      <Form.Label>Milestone</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        value={{
                          value: formik.values.milestone,
                          label: formik.values.milestone,
                        }}
                      />
                    </Form.Group>
                  )}
                </>
                <hr />
                <section className="date">
                  <h2>Invoice Details</h2>

                  <Form.Group className="form-mb">
                    <Form.Label>Pending Transaction</Form.Label>
                    <div className="row align-items-center">
                      <div className="col-8">
                        <Form.Control
                          type="text"
                          placeholder="..."
                          name="pendingTransaction"
                          value={formik.values.pendingTransaction}
                          readOnly
                          onChange={formik.handleChange}
                          onClick={() => setShowPermitModal(true)}
                        />
                      </div>

                      <div className="col">
                        <Button
                          onClick={() => setShowPermitModal(true)}
                          variant="light-blue"
                          className="text-primary"
                        >
                          Get Invoice Number
                        </Button>
                      </div>
                    </div>
                  </Form.Group>

                  <Row className="form-mb">
                    <Form.Group as={Col}>
                      <Form.Label>Sales Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        dateFormat="MMM DD, YYYY"
                        name="salesDate"
                        inputProps={{
                          className: `date-input form-control ${
                            formik.touched.salesDate &&
                            !!formik.errors.salesDate
                              ? "is-invalid"
                              : ""
                          }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.salesDate}
                        onChange={(date) => {
                          formik.setFieldValue("salesDate", date, true);
                        }}
                        onBlur={() => formik.setFieldTouched("salesDate", true)}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Invoice Cat </Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        isSearchable={false}
                        options={data.invoiceCat}
                        value={data.invoiceCat.find(
                          (el) => el.value === formik.values.invoiceCat
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("invoiceCat", value)
                        }
                        onBlur={() =>
                          formik.setFieldTouched("invoiceCat", true)
                        }
                        className={
                          formik.touched.invoiceCat &&
                          !!formik.errors.invoiceCat
                            ? "is-invalid"
                            : ""
                        }
                      />
                      {formik.touched.invoiceCat && formik.errors.invoiceCat ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.invoiceCat}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Row>

                  {appSettings.requireSalesRep && (
                    <Form.Group as={Col} className="form-mb">
                      <Form.Label>Sale Rep</Form.Label>
                      <div className="row align-items-center">
                        <div className="col-8">
                          <Form.Control
                            type="text"
                            placeholder="..."
                            //name="saleRep"
                            value={formik.values.Sale_Rep_Name}
                            readOnly
                          />
                        </div>

                        <div className="col">
                          <Button
                            onClick={() => getSaleRep()}
                            variant="light-blue"
                            className="text-primary"
                          >
                            Get Sale Rep
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                  )}

                  {/*   <Form.Group className="form-mb">
                  <Form.Label className="mb-1">Proforma No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Proforma No."
                    name="proformaNumber"
                    value={formik.values.proformaNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group> */}
                </section>

                <section className="date">
                  <h2>Sales Information</h2>
                  {/* <Form.Label>Sales Type</Form.Label>
                  <div
                    key={`inline-radio`}
                    className="d-flex justify-content-between"
                  >
                    <Form.Check
                      inline
                      label="Retail"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Retail"}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Wholesale"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Wholesale"}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Distributor"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Distributor"}
                      onChange={formik.handleChange}
                    />
                  </div> */}

                  <Form.Group className="mb-3 mt-2">
                    {/* <Form.Label>Printer Type</Form.Label> */}
                    {/* <Select
                      classNamePrefix="form-select"
                      placeholder="Select printer"
                      isSearchable={false}
                      value={printers.find(
                        (el) => el.value === formik.values.printer
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("printer", value)
                      }
                      options={printers}
                    /> */}

                    <div className="mt-2 form-mb d-flex gap-3">
                      <Form.Check
                        inline
                        label="Send Email"
                        name="sendEmail"
                        type={"checkbox"}
                        checked={formik.values.sendEmail}
                        onChange={formik.handleChange}
                      />
                      <Form.Check
                        inline
                        label="Print Receipt"
                        name="printReciept"
                        type={"checkbox"}
                        checked={formik.values.printReciept}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Form.Group>
                </section>

                <section className="date">
                  <h2>Payment Details</h2>

                  <div className="row mb-3">
                    <Form.Group className="col-6">
                      <Form.Label>Payment method</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPlacement="top"
                        placeholder="Choose method"
                        isSearchable={false}
                        value={paymentMethod.find(
                          (el) => el.value === formik.values.PaymentType
                        )}
                        options={paymentMethod}
                        onChange={({ value }) => handlePaymentMethod(value)}
                        getOptionLabel={(el) => (
                          <div className="label-with-icon d-flex gap-2 align-items-center">
                            <span>{el.icon}</span>{" "}
                            <span className="fw-5">{el.label}</span>
                          </div>
                        )}
                        //  menuIsOpen={true}
                      />
                    </Form.Group>

                    {formik.values.PaymentType === "Split Payment" ||
                    formik.values.PaymentType === "Split Payment Multiple" ? (
                      <Form.Group className="col-6">
                        <Form.Label>Cash Amount</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="cashAmount"
                          value={formik.values.cashAmount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group className="col-6">
                        <Form.Label>Amount Paid</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="amountPaid"
                          value={formik.values.amountPaid}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                          readOnly={
                            formik.values.PaymentType === "Customer Account"
                          }
                        />
                      </Form.Group>
                    )}
                  </div>

                  {formik.values.PaymentType === "Split Payment Multiple" && (
                    <div className="border p-3 mb-3">
                      <Form.Group className="col-12 mb-3 form-group-holder">
                        <Form.Label>Credit/Debit Card (POS)</Form.Label>
                        <FieldArray
                          name="splitPaymentCardArray"
                          render={(arrayHelpers) => (
                            <div>
                              {formik.values.splitPaymentCardArray.map(
                                (el, index) => (
                                  <div key={index} className="mb-3">
                                    <div className="row align-items-center">
                                      <div className="col-md-7 ">
                                        <CurrencyCustomInput
                                          currencySymbol={currencySymbol}
                                          name={`splitPaymentCardArray[${index}].amount`}
                                          value={
                                            formik.values.splitPaymentCardArray[
                                              index
                                            ].amount
                                          }
                                          onValueChange={(value, name) => {
                                            formik.setFieldValue(name, value);
                                          }}
                                          placeholder="0.00"
                                        />
                                      </div>
                                      <div className="col-md-4 pe-0">
                                        <Select
                                          classNamePrefix={"form-select"}
                                          placeholder="Bank"
                                          options={data.banks}
                                          value={data.banks.find(
                                            (el) =>
                                              el.value ===
                                              formik.values
                                                .splitPaymentCardArray[index]
                                                .bank
                                          )}
                                          onChange={({ value }) =>
                                            formik.setFieldValue(
                                              `splitPaymentCardArray[${index}].bank`,
                                              value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-1 px-1">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          ✖
                                        </button>
                                      </div>
                                    </div>

                                    <ErrorMessage
                                      component="span"
                                      className="text-danger mb-2"
                                      name={`splitPaymentCardArray[${index}].bank`}
                                    />

                                    <Form.Group className="col-12 mb-3 mt-2">
                                      <Form.Label>POS Charge</Form.Label>
                                      <CurrencyCustomInput
                                        currencySymbol={currencySymbol}
                                        name={`splitPaymentCardArray[${index}].posCharge`}
                                        value={
                                          formik.values.splitPaymentCardArray[
                                            index
                                          ].posCharge
                                        }
                                        onValueChange={(value, name) => {
                                          formik.setFieldValue(name, value);
                                        }}
                                      />
                                    </Form.Group>
                                    <hr />
                                  </div>
                                )
                              )}
                              <div className="d-flex justify-content-end px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      bank: "",
                                      amount: "",
                                      posCharge: "",
                                    })
                                  }
                                >
                                  + Add
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </Form.Group>

                      <hr />

                      <Form.Group className="col-12 mb-3">
                        <Form.Label>Direct Bank Transfer</Form.Label>
                        <FieldArray
                          name="splitDirectBankTransferArray"
                          render={(arrayHelpers) => (
                            <div>
                              {formik.values.splitDirectBankTransferArray.map(
                                (el, index) => (
                                  <div key={index} className="mb-4">
                                    <div className="row align-items-center">
                                      <div className="col-md-7 ">
                                        <CurrencyCustomInput
                                          currencySymbol={currencySymbol}
                                          name={`splitDirectBankTransferArray[${index}].amount`}
                                          value={
                                            formik.values
                                              .splitDirectBankTransferArray[
                                              index
                                            ].amount
                                          }
                                          onValueChange={(value, name) => {
                                            formik.setFieldValue(name, value);
                                          }}
                                          placeholder="0.00"
                                        />
                                      </div>
                                      <div className="col-md-4 pe-0">
                                        <Select
                                          classNamePrefix={"form-select"}
                                          placeholder="Bank"
                                          options={data.banks}
                                          value={data.banks.find(
                                            (el) =>
                                              el.value ===
                                              formik.values
                                                .splitDirectBankTransferArray[
                                                index
                                              ].bank
                                          )}
                                          onChange={({ value }) =>
                                            formik.setFieldValue(
                                              `splitDirectBankTransferArray[${index}].bank`,
                                              value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-1 px-1">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          ✖
                                        </button>
                                      </div>
                                    </div>

                                    <ErrorMessage
                                      component="span"
                                      className="text-danger mb-2"
                                      name={`splitDirectBankTransferArray[${index}].bank`}
                                    />

                                    <hr />
                                  </div>
                                )
                              )}
                              <div className="d-flex justify-content-end px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({ bank: "", amount: "" })
                                  }
                                >
                                  + Add
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </Form.Group>
                      <hr />

                      <Form.Group className="col-12 mb-3">
                        <Form.Label>Cheque</Form.Label>
                        <FieldArray
                          name="splitPaymentChequeArray"
                          render={(arrayHelpers) => (
                            <div>
                              {formik.values.splitPaymentChequeArray.map(
                                (el, index) => (
                                  <div key={index} className="mb-4">
                                    <div className="row align-items-center">
                                      <div className="col-md-7 ">
                                        <CurrencyCustomInput
                                          currencySymbol={currencySymbol}
                                          name={`splitPaymentChequeArray[${index}].amount`}
                                          value={
                                            formik.values
                                              .splitPaymentChequeArray[index]
                                              .amount
                                          }
                                          onValueChange={(value, name) => {
                                            formik.setFieldValue(name, value);
                                          }}
                                          placeholder="0.00"
                                        />
                                      </div>
                                      <div className="col-md-4 pe-0">
                                        <Select
                                          classNamePrefix={"form-select"}
                                          placeholder="Bank"
                                          options={data.banks}
                                          value={data.banks.find(
                                            (el) =>
                                              el.value ===
                                              formik.values
                                                .splitPaymentChequeArray[index]
                                                .bank
                                          )}
                                          onChange={({ value }) =>
                                            formik.setFieldValue(
                                              `splitPaymentChequeArray[${index}].bank`,
                                              value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-1 px-1">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          ✖
                                        </button>
                                      </div>
                                    </div>
                                    <ErrorMessage
                                      component="span"
                                      className="text-danger mb-2"
                                      name={`splitPaymentChequeArray[${index}].bank`}
                                    />

                                    <Form.Group className="col-12 mb-3 mt-2">
                                      <Form.Label>Cheque Number</Form.Label>
                                      <Form.Control
                                        name={`splitPaymentChequeArray[${index}].chequeNumber`}
                                        value={
                                          formik.values.splitPaymentChequeArray[
                                            index
                                          ].chequeNumber
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      <ErrorMessage
                                        component="span"
                                        className="text-danger mb-2"
                                        name={`splitPaymentChequeArray[${index}].chequeNumber`}
                                      />
                                    </Form.Group>
                                    <hr />
                                  </div>
                                )
                              )}
                              <div className="d-flex justify-content-end px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({ bank: "", amount: "" })
                                  }
                                >
                                  + Add
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {formik.values.PaymentType === "Customer Account" ? (
                    <Form.Group className="col-12 mb-3">
                      <Form.Label>Customer Balance</Form.Label>
                      <CurrencyCustomInput
                        currencySymbol={currencySymbol}
                        name="customerBalance"
                        value={selectedCustomer?.balance}
                        readOnly
                        placeholder="0.00"
                      />
                    </Form.Group>
                  ) : null}

                  {formik.values.PaymentType === "Credit/Debit Card" ||
                  formik.values.PaymentType === "Customer Account" ||
                  (formik.values.splitPaymentType === "card" &&
                    formik.values.PaymentType === "Split Payment") ? (
                    <Form.Group className="col-12 mb-3">
                      <Form.Label>POS Charge</Form.Label>
                      <CurrencyCustomInput
                        currencySymbol={currencySymbol}
                        name="posCharge"
                        value={formik.values.posCharge}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Form.Group>
                  ) : null}

                  {/* Split Payment */}
                  {formik.values.PaymentType === "Split Payment" ? (
                    <div>
                      <div className="d-flex justify-content-between mb-2 pb-1">
                        <Form.Check
                          inline
                          label="Card"
                          name="splitPaymentType"
                          value="card"
                          type={"radio"}
                          id={`splitPaymentType-1`}
                          checked={formik.values.splitPaymentType === "card"}
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Direct Transfer"
                          value="directTransfer"
                          name="splitPaymentType"
                          type={"radio"}
                          id={`splitPaymentType-2`}
                          checked={
                            formik.values.splitPaymentType === "directTransfer"
                          }
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Cheque"
                          value="cheque"
                          name="splitPaymentType"
                          type={"radio"}
                          id={`splitPaymentType-3`}
                          checked={formik.values.splitPaymentType === "cheque"}
                          onChange={formik.handleChange}
                        />
                      </div>

                      <Form.Group className="form-mb">
                        <Form.Label>Amount</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          value={amount}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    </div>
                  ) : null}

                  {!formik.values.jobNumber && (
                    <Row className="mb-3">
                      <Form.Group className="col-6 mb-3">
                        <Form.Label>Loading Charge</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="loadingCharge"
                          placeholder="0.00"
                          value={formik.values.loadingCharge}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-6 mb-3">
                        <Form.Label>Offloading Charge</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="offloadingCharge"
                          placeholder="0.00"
                          value={formik.values.offloadingCharge}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="col-12 mb-3">
                        <Form.Label>Transport Charge</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="transportCharge"
                          placeholder="0.00"
                          value={formik.values.transportCharge}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>
                    </Row>
                  )}

                  <section>
                    <div className="row form-mb">
                      <Form.Group className="col-6">
                        <Form.Label>Tax </Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select"
                          isSearchable={false}
                          value={taxOptions.find(
                            (el) => el.value === formik.values.taxType
                          )}
                          options={taxOptions}
                          onChange={({ value }) =>
                            formik.setFieldValue("taxType", value)
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Label>
                          {formik.values.taxType &&
                          formik.values.taxType !== "None"
                            ? formik.values.taxType
                            : "VAT"}
                        </Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="tax"
                          value={taxValue}
                          onValueChange={(value, name) => {
                            //   formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    </div>
                    {generalSettings?.poNumber && (
                      <Form.Group className="form-mb">
                        <Form.Label className="mb-1">Po No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Po No."
                          name="poNumber"
                          value={formik.values.poNumber}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    )}
                  </section>

                  <section>
                    <div className="row">
                      <Form.Group className="col-6 mt-2 form-mb">
                        <Form.Label>Shipping Cost</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="shippingCost"
                          value={formik.values.shippingCost}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>

                      <Form.Group className="col-6 mt-2 form-mb">
                        <Form.Label>Due In (days)</Form.Label>
                        <NumberCustomInput
                          name="dueIn"
                          value={formik.values.dueIn}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                        />
                      </Form.Group>
                    </div>

                    <section>
                      <Form.Group className=" mt-2 form-mb">
                        <Form.Label>
                          Clearing charges, Custom duty and Other Charges
                        </Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="otherCharges"
                          value={formik.values.otherCharges}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    </section>

                    {!formik.values.jobNumber && (
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Supply now"
                          className="mb-3"
                          name="supplyNow"
                          checked={formik.values.supplyNow}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleSupplyNow(e.target.checked);
                          }}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          name="printWaybill"
                          label="Print Waybill"
                          className="mb-3"
                          checked={formik.values.printWaybill}
                          onChange={formik.handleChange}
                        />
                      </div>
                    )}
                  </section>
                </section>

                <section className="buttons">
                  <Button
                    type="button"
                    variant="outline-primary"
                    // className="border-0"
                    onClick={() => discard()}
                  >
                    Discard Invoice
                  </Button>
                  <Button type="submit" variant="primary">
                    Post Invoice
                  </Button>
                </section>
              </Form>
            </FormikProvider>
          </section>
        </div>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={handleSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddRodItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={lockedTableData}
          selectedCustomer={selectedCustomer}
          withQuantity={true}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItem={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

      {showNewRequisitionModal && (
        <NewRequisitionModal
          modalTitle="Send for Approval"
          dataForPendingPermit={JSON.stringify({
            items: tableData.map((el) => ({
              ...el,
              Quantity: el.Quantity,
              requirePermission: true,
            })),
            ...formik.values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
          })}
          defaultFormValues={{
            title: location.state.job?.title,
            description: location.state.job?.description,
            type: "Pending Service Invoice",
            cost: location.state.job?.jobCost,
            vendor: "  ",
            jobNumber: location.state.job?.jobNumber,
          }}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
          /* handleCreatedRequisition={handleCreatedRequisition} */
        />
      )}

      <ModalLoader
        show={
          createRodPermitMutation.isLoading ||
          createRodInvoiceMutation.isLoading ||
          isLoading
        }
      />
    </main>
  );
}
