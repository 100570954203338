import { useEffect, useState, useRef, Fragment, useMemo } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
  Dropdown,
} from "react-bootstrap";
import ArrowCollapseDownIcon from "mdi-react/ArrowCollapseDownIcon";
import "./../../assets/scss/requisition/index.scss";
import {
  LinkVariantIcon,
  Logo,
  PrintIcon,
  RecieptIcon,
  SignatureIcon,
} from "../Icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { services, HRFrontEndUrl } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { find, isEmpty, lowerCase, max } from "lodash";
import {
  copyText,
  formDataForFormBuider,
  formatDate,
  formatNumberSystem,
  isInventoryOrFundRequest,
  maxTopPopperConfig,
  nanoid,
  searchParamsToObject,
  styleHelper,
  waitFor,
} from "../../utils/helpers";
import useDebounce, {
  useCanGoBack,
  useHistoryStack,
  useIsAdmin,
  useIsCashier,
} from "../../utils/hooks";
import { toast } from "react-toastify";
import { requisitionActions } from "../../utils/reactQueryActions";
import currency from "currency.js";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { ApproveAndCloseDialog, RejectDialog } from "./Dialog";
// import printJS from "print-js";
import { fetchActionsUtil } from "../../utils/helpers";
import { LoanAccountModal } from "../modals/LoanAccountModal";
import ApprovalBadge from "../utils/ApprovalBadge";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import FundRequestForm from "./FundRequestForm";
import CachedIcon from "mdi-react/CachedIcon";
import { currenciesOptions } from "../../utils/currencies";
import eventBus from "../../utils/EventBus";
import ModalLoader from "../utils/ModalLoader";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import CropPortraitIcon from "mdi-react/CropPortraitIcon";
import CropLandscapeIcon from "mdi-react/CropLandscapeIcon";
import ShareVariantIcon from "mdi-react/ShareVariantIcon";
import { useStoreState } from "easy-peasy";
import * as yup from "yup";
import Datetime from "react-datetime";

export default function PreviewModal({ location }) {
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );

  const generalSettings = useStoreState((state) => state.generalSettings);
  const initialRequestData = (requisition = {}) => {
    return lowerCase(requisition?.type) === "fund request" ||
      lowerCase(requisition?.type) === "budget"
      ? {
          description: "",
          unitCost: "",
          quantity: "",
        }
      : lowerCase(requisition?.type) === "material request"
      ? {
          barcode: "",
          itemName: "",
          description: "",
          quantityRequested: "",
          availability: "",
          quantityApproved: "",
        }
      : {
          barcode: "",
          itemName: "",
          unitCost: "",
          quantity: "",
        };
  };

  let { id } = useParams();
  const iframeRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const goBack = useCanGoBack();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [fundRequest, setFundRequest] = useState(initialRequestData());
  const { user: authUser, backendUrl } = useAuth();
  const [file, setFile] = useState();
  const [loanAmount, setLoanAmount] = useState(0);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [reciever, setReceiver] = useState();

  const formik = useFormik({
    initialValues: { expectedDeliveryDate: new Date(), projectDescription: "" },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {},
  });

  const getNextUserToPerformAction = (requisition) => {
    const requisitiontracks = requisition.requisitiontracks;
    /*    if (
      ["fund request", "material request"].includes(
        lowerCase(requisition?.type)
      )
    ) {
      let permissions = JSON.parse(requisition.requiredSignatoriesStaff_ID);

      if (permissions) {
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length;
        setReceiver(permissions[lastTrack]);
      }
      return;
    } */

    //---------------------------------------------------------------------
    let permissions = [];
    const page = JSON.parse(requisition.filledFormData)[0];
    const signatories = page.children
      .filter((el) => el?.type === "signatoryElement")
      .map((el) => el.props.fields);
    if (signatories) {
      permissions = signatories.flat().map((el) => el.permission);

      if (permissions) {
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length;
        setReceiver(permissions[lastTrack]);
      }
    }
  };

  const canApproveAndClose = (requisition = {}) => {
    if (
      ["fund request", "material request"].includes(
        lowerCase(requisition?.type)
      ) &&
      requisition.requiredSignatoriesStaff_ID
    ) {
      let permissions = JSON.parse(requisition.requiredSignatoriesStaff_ID);

      //  for Staff
      const forStaff = permissions.find((el) => el.staff === authUser.Staff_ID);

      //  Department
      const anyDepartment = permissions.find((el) => el.department === "");

      //
      const anyJobGrade = permissions.find(
        (el) => el.department === authUser.Department && el.jobGrade === ""
      );

      const anyStaff = permissions.find(
        (el) =>
          el.department === authUser.Department &&
          el.jobGrade === authUser.jobGrade &&
          el.staff === ""
      );

      const resolveLimit = (selection) => {
        if (selection?.limitType === "any") return Infinity;
        return selection && selection.limit ? Number(selection.limit) : 0;
      };

      if ("fund request" === lowerCase(requisition?.type)) {
        const limits = [
          resolveLimit(forStaff),
          resolveLimit(anyDepartment),
          resolveLimit(anyJobGrade),
          resolveLimit(anyStaff),
        ];

        const highestLimit = max(limits);
        console.log(
          highestLimit,
          requisition?.amount,
          Number(requisition?.amount) <= Number(highestLimit)
        );
        return Number(requisition?.amount) <= Number(highestLimit);
      } else {
        console.log(forStaff, anyDepartment, anyJobGrade, anyStaff);
        return forStaff || anyDepartment || anyJobGrade || anyStaff;
      }
    } else {
      return true;
    }
  };

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    //  force rerender
    data.requisition.timestamp = String(Date.now());

    if (isInventoryOrFundRequest(data.requisition.type)) {
      setTableData(
        pullDataFromString(data.requisition.description, data.requisition.type)
      );
    }
    if (data.requisition?.filledFormData) {
      getNextUserToPerformAction(data.requisition);
      setIsLoadingIframe(true);
    } else if (
      ["fund request", "material request"].includes(
        lowerCase(data.requisition?.type)
      )
    ) {
      //  getNextUserToPerformAction(data.requisition);
      canApproveAndClose(data.requisition);
      setIsLoadingIframe(false);
    } else {
      setIsLoadingIframe(false);
    }
    // Set form values
    if (data.requisition) {
      const requisitionDataFromServer = data.requisition;
      formik.setValues({
        ...formik.values,
        expectedDeliveryDate: requisitionDataFromServer?.expectedDeliveryDate
          ? new Date(requisitionDataFromServer?.expectedDeliveryDate)
          : new Date(),
        projectDescription: requisitionDataFromServer.projectDescription,
      });
    }

    //
    setFundRequest({ ...initialRequestData(data.requisition) });
    return data;
  };

  const {
    data: { requisition, company, orientation, pageSize } = {
      requisition: null,
      company: null,
      orientation: null,
      pageSize: null,
    },
    isFetching,
    refetch,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    if (requisition) {
      const queryParams = searchParamsToObject(searchParams.entries());
      if (queryParams.action) {
        if (canPerformAction(requisition)) {
          if (queryParams.action === "approveAndClose") {
            approveAndClose();
          } else if (queryParams.action === "reject") {
            reject();
          }
        }
        delete queryParams.action;
        setSearchParams({ ...queryParams });
      }

      if (lowerCase(requisition.type) === "loan") {
        setLoanAmount(requisition.amount);
        // console.log(requisition);
      }
    }
  }, [requisition]);

  const reject = async () => {
    await RejectDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
    });
  };

  const approveAndClose = async () => {
    //
    /*  if (lowerCase(requisition.type) === "fund request") {
      if (isEmpty(requisition.paymentType)) {
        // return toast.error("Requisition still has some pending Actions");
      }

      if (requisition?.requiredSignatoryStaff_ID) {
        const requiredUserInTrack = requisition?.requisitiontracks.find(
          (el) =>
            el.sentbyUser === authUser.Staff_ID ||
            el.receivedbyUser === authUser.Staff_ID
        );
        if (
          isEmpty(requiredUserInTrack) &&
          authUser.Staff_ID !== requisition?.requiredSignatoryStaff_ID
        ) {
          return toast.error(
            `${requisition.requiredUser.Name} has to approve before closing `
          );
        }
      }
    } */

    if (
      lowerCase(requisition.type) === "fund request" &&
      isEmpty(requisition?.paymentType)
    ) {
      navigate(`/requisition/request/approve-and-close/${id}`);
    } else {
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      });
    }
  };

  const pullDataFromString = (text, requisitionType) => {
    let res = "";
    if (
      lowerCase(requisitionType) === "fund request" ||
      lowerCase(requisitionType) === "budget"
    ) {
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            description: data[0],
            unitCost: Number(data[1]).toFixed(2),
            quantity: data[2],
            subtotal: data[1] * data[2],
          };
        })
        .filter((el) => el.description);
      console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "inventory") {
      console.log(text);
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            barcode: data[0],
            itemName: data[1],
            unitCost: Number(data[2]).toFixed(2),
            quantity: data[3],
            subtotal: data[2] * data[3],
          };
        })
        .filter((el) => el.barcode);
      //   console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "material request") {
      console.log(text);
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            barcode: data[0],
            itemName: data[1],
            description: data[2],
            quantityApproved: data[3],
            availability: data[4],
            quantityRequested: data[5],
          };
        })
        .filter((el) => el.barcode);
      // console.log(res);
      return res;
    }

    /*res = text
      .replaceAll("SELECTED INVENTORY ITEMS", "")
      .replaceAll("Barcode:", "#_$_%")
      .replaceAll("Item Name:", "#_$_%")
      .replaceAll("Quantity:", "#_$_%")
      .replaceAll(/(\r\n|\n|\r)/gm, "")
      .replace(/\s+/g, "")
      .trim()
      .split("#_$_%")
      .filter((el) => el);

    res = chunk(res, 3).map((el) => ({
      Barcode: el[0],
      ItemName: el[1],
      Quantity: el[2],
    }));

    return res;*/
  };

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el.unitCost, {
            precision: 2,
          }).multiply(el.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 800);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const updateRequisition = async (payload) => {
    // console.log(payload);
    let formData = new FormData();

    // GET FILES FOR UPLOAD --------------------------------------
    if (payload.filledFormData) {
      payload.Template = payload.filledFormData;
      formData = formDataForFormBuider(payload, formData);
    }

    //------------------------------------------------------------
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }

    let response = await fetch(`${backendUrl}/api/requisition/update/${id}`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const updateRequisitionMutation = useMutation(
    (payload) => updateRequisition(payload),
    {
      onSuccess: ({ description, filledFormData, amount }) => {
        queryClient.setQueryData(
          [requisitionActions.GET_REQUISITION_DETAILS, id],
          (data) => {
            data.requisition.description = description;
            data.requisition.filledFormData = filledFormData;
            data.requisition.amount = amount;
            sendNewSetupData(filledFormData);
            return data;
          }
        );
        // setFile(null);
        toast.success("Requisition updated successfully");
      },
      onError: () => {
        toast.error("Unable to save");
      },
    }
  );

  const save = () => {
    // if (!file) toast.error("Please attach your signature");
    let description = "";
    if (
      lowerCase(requisition?.type) === "fund request" ||
      lowerCase(requisition?.type) === "budget"
    ) {
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest.description ? [fundRequest] : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "inventory") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "material request") {
      console.log(fundRequest);
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest?.itemName
          ? [{ ...fundRequest, barcode: nanoid() }]
          : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    }

    description = description + "&&&";
    const realTotal = currency(fundRequest.unitCost)
      .multiply(fundRequest.quantity)
      .add(total).value;
    updateRequisitionMutation.mutate({
      requestid: id,
      description,
      file,
      amount: isNaN(realTotal) ? 0 : realTotal,
      ...formik.values,
    });
  };

  const saveLoanAmount = async () => {
    try {
      await fetchActionsUtil(
        `${backendUrl}/api/requisition/update-amount/${id}`,
        "PUT",
        "",
        { amount: loanAmount }
      );
      toast.success("Amount Successfully Updated");
    } catch {
      toast.error("Unable to update");
    }
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
    //  setActiveTableIndex(index);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    console.log(item);
    /* Bar_Code: "0003";
    Branch: "HQ";
    Cat_Name: "A2 Lubricant";
    Item_Name: "A2 Oil 1L";
    Quantity: 12;
    UnitCost: 1450;
    UnitPrice: 1650;
    imagepath: ""; */
    if (lowerCase(requisition?.type) === "inventory") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        unitCost: item.UnitCost,
        quantity: 1,
      };
    } else if (lowerCase(requisition?.type) === "material request") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        description: "",
        quantityRequested: "",
        availability: "",
        quantityApproved: "",
      };
    } else if (lowerCase(requisition?.type) === "fund request") {
    }

    let oldTableData = tableData;

    if (activeTableIndex === -1) {
      oldTableData = [...oldTableData, item];
    } else {
      oldTableData[activeTableIndex] = item;
    }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const handleFundRequestInput = (value, name) => {
    //  console.log(value, name);
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      setFundRequest({ ...initialRequestData(requisition) });
    }, 10);
  };

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;
    const pendingRequisition = last(requisitionData?.requisitiontracks);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const canModifyFundRequest = (requisition) => {
    return (
      ["fund request"].includes(lowerCase(requisition?.type)) &&
      (authUser.Staff_ID === requisition?.requestby || isCashier)
    );
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    let fileInput = e.target;
    setFile(fileInput.files[0]);
  };

  const download = () => {
    const link = document.createElement("a");
    link.setAttribute("download", `${requisition.requestid}.png`);
    link.href = `${backendUrl}/api/requisition/pdf/${
      requisition.requestid
    }?download=true`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            departments: [],
            template: JSON.parse(requisition.filledFormData),
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendNewSetupData = (filledFormData) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            departments: [],
            template: JSON.parse(filledFormData),
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const saveForm = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const printForm = (orientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      if (orientation) {
        iframe.contentWindow.postMessage(
          {
            type: "OREINTATION",
            data: {
              orientation,
            },
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );

        //  Delay -------------

        setTimeout(() => {
          iframe.contentWindow.postMessage(
            {
              type: "PRINT",
            },
            process.env.REACT_APP_FORM_BUILDER_URL
          );
        }, 500);

        //-------------------
      } else {
        iframe.contentWindow.postMessage(
          {
            type: "PRINT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  };

  const setOrientation = (printOrientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      // setShowNewCustomRequisitionModal(false);
    } else if (message.data.type === "SAVE") {
      updateRequisitionMutation.mutate({
        requestid: id,
        filledFormData: message.data.value,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  eventBus.useCustomEventListener("REQUISITION_UPDATED", (requisition) => {
    if (requisition?.filledFormData) {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              template: JSON.parse(requisition.filledFormData),
            },
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }

      queryClient.setQueryData(
        [requisitionActions.GET_REQUISITION_DETAILS, id],
        (data) => {
          data.requisition.filledFormData = requisition?.filledFormData;
          return data;
        }
      );
    }
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === (requisition?.currency ? requisition?.currency : "NGN")
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [requisition?.currency]);

  return (
    <div>
      <Modal
        show={true}
        onHide={() => goBack("/requisition/request")}
        dialogClassName={`requisition-details-modal preview-modal ${
          orientation === "Landscape" ? "modal-90w" : ""
        }`}
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        fullscreen={styleHelper.isMobile}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{company?.CompName || "..."}</h1>
            <p>{company?.AddressLine1 || "..."}</p>{" "}
            <p>
              {formatNumberSystem(
                requisition?.numberPrefix,
                requisition?.currentNumber
              )}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-info p-4 pt-0 d-flex justify-content-between">
            <p className=" d-flex gap-3 title">
              <span>From:</span>
              <span className="fw-5">
                {requisition ? (
                  requisition.type === "Loan" ? (
                    <a
                      href={`${HRFrontEndUrl}/dashboard/profile/${
                        JSON.parse(requisition.loanDetails).employeeId
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {JSON.parse(requisition.loanDetails).employeeName}
                    </a>
                  ) : (
                    last(requisition?.requisitiontracks)?.sentbyUser.Name
                  )
                ) : (
                  "..."
                )}
              </span>
            </p>

            <div className="d-flex action-btns d-print-none">
              <Button onClick={() => refetch()} variant="light">
                <CachedIcon />
              </Button>
              {requisition?.filledFormData ? (
                <Button
                  className="btn d-flex align-items-center justify-content-center"
                  title="Print"
                  variant="light"
                  onClick={() => printForm()}
                >
                  <PrintIcon />
                </Button>
              ) : (
                <>
                  <a
                    href={`${backendUrl}/api/requisition/pdf/${
                      requisition?.requestid
                    }`}
                    className="btn d-flex align-items-center justify-content-center"
                    title="Print"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PrintIcon />
                  </a>
                  <Button
                    onClick={() =>
                      copyText(
                        `${backendUrl}/api/requisition/pdf/${
                          requisition?.requestid
                        }`,
                        "PDF Link Copied"
                      )
                    }
                    variant="light"
                  >
                    <ShareVariantIcon />
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="preview-content border-bottom">
            <div className="content">
              <div className="preview-header border-bottom mb-3">
                {/* <Logo /> */}
                <img
                  width={50}
                  src={`${backendUrl}/images/company-logo.png`}
                  alt="logo"
                />

                <span>
                  Date:{" "}
                  {requisition?.regdate
                    ? format(new Date(requisition?.regdate), "eee MMMM do yyyy")
                    : "..."}
                </span>
              </div>
              <div className="preview-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2>
                      {requisition?.title}{" "}
                      {requisition?.jobNumber && requisition?.type !== "Budget"
                        ? ` / ${requisition?.jobNumber} - ${
                            requisition?.job?.title
                          }`
                        : ``}
                    </h2>
                    <p>
                      {requisition?.type === "Loan" ? (
                        <ApprovalBadge
                          text={requisition?.type}
                          // className="approval"
                          className="loanstyle"
                        />
                      ) : (
                        requisition?.type
                      )}
                    </p>
                  </div>
                  <div>
                    {lowerCase(requisition?.status) === "approved closed" &&
                    lowerCase(requisition?.type) === "loan" ? (
                      <div className="mx-3">
                        <p className="text-danger fs-4 fw-bold mb-2">Unpaid</p>
                        <button
                          className="btn btn-success text-white fs-6 px-4"
                          onClick={() => setOpenAccountModal(true)}
                        >
                          Pay
                        </button>
                      </div>
                    ) : (
                      lowerCase(requisition?.status) === "paid" && (
                        <p className="text-success fs-4 fw-bold mx-3">Paid</p>
                      )
                    )}
                  </div>
                </div>
                {![
                  "inventory",
                  "fund request",
                  "material request",
                  "budget",
                ].includes(lowerCase(requisition?.type)) && (
                  <p className="mb-md-3">{requisition?.description}</p>
                )}
                <>
                  <Table borderless className="form-table">
                    <tbody className="d-md-flex flex-wrap">
                      <tr className="d-flex flex-column w-50">
                        <td>Department:</td>
                        <td>{requisition?.requesttoUser?.Department}</td>
                      </tr>

                      <tr className="d-flex flex-column w-50">
                        <td>Reciepient:</td>
                        <td>{requisition?.requesttoUser?.Name}</td>
                      </tr>

                      {requisition?.vendor && (
                        <tr className="d-flex flex-column w-50">
                          <td> Vendor:</td>
                          <td> {requisition?.vendor}</td>
                        </tr>
                      )}

                      {requisition?.requiredSignatoryStaff_ID && (
                        <tr className="d-flex flex-column w-50">
                          <td> Mandatory Signatory:</td>
                          <td> {requisition.requiredUser.Name}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
                {lowerCase(requisition?.type) === "loan" && (
                  <>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <tbody>
                        <tr>
                          <td className="fs-6 fw-bold">Amount</td>
                          <td>
                            <CurrencyInput
                              className="form-control border-0 px-1 fw-bold"
                              value={loanAmount}
                              onKeyDown={(e) => {
                                e.keyCode === 13 && e.target.blur();
                                e.key === "Enter" && saveLoanAmount();
                              }}
                              name="loanamount"
                              onValueChange={(value, name) =>
                                setLoanAmount(value)
                              }
                              onEnter
                              decimalsLimit={2}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
                {lowerCase(requisition?.type) === "material request" ? (
                  <>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Project Description :</Form.Label>
                      <label className="border p-0 rounded message-area d-block">
                        <Form.Control
                          name="projectDescription"
                          value={formik.values.projectDescription}
                          onChange={formik.handleChange}
                          as="textarea"
                          className="w-100 border-0 p-3"
                          placeholder="Enter a Project  Description"
                          rows={4}
                        />
                      </label>
                    </Form.Group>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Items/Materials</th>
                          <th>Specifications</th>
                          <th>Quantity Requested</th>
                          <th>Availability</th>
                          <th>Quantity Approved</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableData &&
                          tableData.map((el, index) => (
                            <tr
                              key={index}
                              className={`${activeTableIndex === index &&
                                "active-row"}`}
                              onClick={() =>
                                activeTableIndex !== index &&
                                setActiveTableIndex(index)
                              }
                            >
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.itemName}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "itemName",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>{" "}
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.description}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "description",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityRequested}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityRequested",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.availability}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "availability",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityApproved}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityApproved",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          ))}

                        <tr
                          className={`bg-light p-cursor ${activeTableIndex ===
                            -1 && "active-row"}`}
                          /* onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        } */
                        >
                          <td onClick={(e) => createNew(e)}>
                            <span className="">
                              <MagnifyIcon />
                            </span>
                          </td>

                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="itemName"
                              value={fundRequest.itemName}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="description"
                              value={fundRequest.description}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>

                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityRequested"
                              value={fundRequest.quantityRequested}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="availability"
                              value={fundRequest.availability}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityApproved"
                              value={fundRequest.quantityApproved}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Form.Group className="col-4 mb-3 pb-2  mt-2">
                      <Form.Label className="mb-1">
                        Expected Delivery Date
                      </Form.Label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="expectedDeliveryDate"
                        inputProps={{
                          className: `date-input form-control ${
                            formik.touched.expectedDeliveryDate &&
                            !!formik.errors.expectedDeliveryDate
                              ? "is-invalid"
                              : ""
                          }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.expectedDeliveryDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "expectedDeliveryDate",
                            date,
                            true
                          );
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("expectedDeliveryDate", true)
                        }
                      />
                      {formik.touched.expectedDeliveryDate &&
                      !!formik.errors.expectedDeliveryDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.expectedDeliveryDate}
                        </span>
                      ) : null}
                    </Form.Group>
                  </>
                ) : lowerCase(requisition?.type) === "inventory" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Barcode</th>
                        <th>Item Name</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />{" "}
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            <MagnifyIcon />
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(requisition?.type) === "fund request" ||
                  lowerCase(requisition?.type) === "budget" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition) &&
                              !canModifyFundRequest(requisition)
                                ? // authUser.Staff_ID !== requisition?.requestby
                                  { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <input
                                className="form-control border-0 px-1"
                                value={el.description}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "description",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                //   // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}

                      <tr
                        style={
                          !canPerformAction(requisition) &&
                          !canModifyFundRequest(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`bg-light p-cursor ${activeTableIndex ===
                          -1 && "active-row"}`}
                        onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        }
                      >
                        <td />
                        <td>
                          <input
                            className="form-control border-0 px-1 bg-white"
                            name="description"
                            id="fund-request-description"
                            value={fundRequest.description}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onChange={(e) =>
                              handleFundRequestInput(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.unitCost}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            name="unitCost"
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            // disableGroupSeparators
                            decimalsLimit={2}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.quantity}
                            name="quantity"
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            // disableGroupSeparators
                            allowNegativeValue={false}
                            allowDecimals={false}
                          />
                        </td>
                        <td>
                          {fundRequest.unitCost && fundRequest.quantity ? (
                            <CurrencyInput
                              className="form-control border-0 px-0 bg-white"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(
                                  fundRequest.unitCost * fundRequest.quantity
                                )
                                  ? currency(fundRequest.unitCost, {
                                      precision: 2,
                                    }).multiply(fundRequest.quantity)
                                  : ""
                              }
                              // // disableGroupSeparators
                              decimalsLimit={2}
                            />
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [maxTopPopperConfig({})],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable
                      handleSelectedItem={handleSelectedItem}
                      type={
                        lowerCase(requisition?.type) !== "inventory"
                          ? requisition?.type
                          : ""
                      }
                      getItemFrom={
                        lowerCase(requisition?.type) !== "inventory"
                          ? "Equipment"
                          : "Items"
                      }
                    />
                  </Popover>
                </Overlay>
                {(canPerformAction(requisition) ||
                  canModifyFundRequest(requisition)) &&
                isInventoryOrFundRequest(requisition?.type) ? (
                  <div className="d-flex justify-content-between pb-4 mb-4 border-bottom d-print-none">
                    {![null, -1].includes(activeTableIndex) ? (
                      <Button
                        onClick={() => removeSelection()}
                        variant="danger"
                        size="sm"
                        className="text-white px-3 "
                      >
                        Remove Selection
                      </Button>
                    ) : (
                      <span />
                    )}

                    <div className="proceed-actions">
                      <Button
                        disabled={updateRequisitionMutation.isLoading}
                        onClick={() => save()}
                        variant="outline-primary"
                        size="sm"
                      >
                        {updateRequisitionMutation.isLoading
                          ? "Please wait..."
                          : "Save"}
                      </Button>
                    </div>
                  </div>
                ) : null}{" "}
              </div>

              {!isEmpty(requisition?.filledFormData) ? (
                <div>
                  {!isFetching ? (
                    <>
                      <iframe
                        ref={iframeRef}
                        id="Frame"
                        onLoad={(e) => {
                          setIsLoadingIframe(false);
                          sendSetupData();
                          setOrientation({
                            orientation,
                            pageSize,
                          });
                        }}
                        className="w-100 vh-100 border"
                        title="Form Builder"
                        src={`${
                          process.env.REACT_APP_FORM_BUILDER_URL
                        }/form/fill`}
                      />
                      {canPerformAction(requisition) && (
                        <div className="mt-3">
                          <Button
                            disabled={updateRequisitionMutation.isLoading}
                            onClick={() => saveForm()}
                            variant="outline-primary"
                            size="sm"
                            className=" px-4 "
                          >
                            {updateRequisitionMutation.isLoading
                              ? "Please wait..."
                              : "Save"}
                          </Button>
                        </div>
                      )}
                      <hr />
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="preview-footer">
                  {isInventoryOrFundRequest(requisition?.type) &&
                  lowerCase(requisition?.type) !== "material request" ? (
                    <div>
                      <p>Total Amount: </p>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          {currencySymbol}
                        </InputGroup.Text>
                        <CurrencyInput
                          placeholder="0.00"
                          className="form-control"
                          //  // disableGroupSeparators
                          disabled
                          value={total}
                        />
                      </InputGroup>
                    </div>
                  ) : (
                    <hr />
                  )}

                  {requisition &&
                  lowerCase(requisition?.type) === "fund request" ? (
                    <div key={requisition?.timestamp}>
                      <FundRequestForm
                        requisition={requisition}
                        tableData={tableData}
                        total={total}
                        refetch={() => refetch()}
                        fundRequest={fundRequest}
                        currencySymbol={currencySymbol}
                      />
                    </div>
                  ) : null}

                  {requisition?.requisitiontracks.map((el, index) => (
                    <Fragment key={index}>
                      {![
                        "pending",
                        "disapproved",
                        /* "pending payment",
                        "pending retirement", */
                      ].includes(lowerCase(el.status)) ? (
                        <>
                          <Table borderless className="form-table">
                            <tbody className="d-flex">
                              <tr className="d-flex flex-column w-50">
                                <td>Name</td>
                                <td>{el.receivedbyUser?.Name}</td>
                              </tr>
                              <tr className="d-flex flex-column w-50">
                                <td>Date:</td>
                                <td>
                                  {format(
                                    new Date(el.regdate),
                                    "dd - MM - yyyy"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Table borderless className="form-table">
                            <tbody className="d-flex">
                              <tr className="d-flex flex-column w-50">
                                <td>{"  "}</td>
                                <td>{el.comment}</td>
                              </tr>
                              <tr className="d-flex flex-column w-50">
                                <td>
                                  <label for="signature">Signature :</label>
                                </td>
                                <td>
                                  <label className="p-cursor">
                                    <div
                                      className="signature-image"
                                      style={{ height: "12rem" }}
                                    >
                                      {
                                        <img
                                          style={{
                                            width: "12rem",
                                            height: "12rem",
                                          }}
                                          alt=""
                                          src={`${backendUrl}/api/users/signature/${
                                            el.receivedbyUser.Staff_ID
                                          }`}
                                        />
                                      }
                                    </div>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                    </Fragment>
                  ))}
                </div>
              )}

              {/* */}
              <div className="d-flex justify-content-end gap-3">
                <Button
                  onClick={() =>
                    navigate(
                      `/requisition/request/details/${requisition.requestid}`
                    )
                  }
                >
                  View Progress
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>{" "}
        {canPerformAction(requisition) &&
          (requisition.filledFormData ? (
            <Modal.Footer className="d-print-none variant-2 gap-3">
              <Button
                onClick={() => reject()}
                variant="danger"
                className="text-white px-3 reject"
              >
                Reject
              </Button>

              <div className="proceed-actions">
                {reciever ? (
                  <Link
                    to={`/requisition/request/approve-and-send/${id}`}
                    state={{ backgroundLocation: location }}
                    className="btn btn-white border bg-white"
                  >
                    Approve & Sent
                  </Link>
                ) : (
                  <Button
                    className=""
                    onClick={() => approveAndClose()}
                    variant="primary"
                  >
                    Approve & Close
                  </Button>
                )}
              </div>
            </Modal.Footer>
          ) : (
            <Modal.Footer className="d-print-none variant-2">
              <Button
                onClick={() => reject()}
                variant="danger"
                className="text-white px-3 reject"
              >
                Reject
              </Button>

              <div className="proceed-actions">
                <Link
                  to={`/requisition/request/approve-and-send/${id}`}
                  state={{ backgroundLocation: location }}
                  className="btn btn-white border bg-white"
                >
                  Approve & Sent
                </Link>

                {canApproveAndClose(requisition) && (
                  <Button
                    className="approve-and-close"
                    onClick={() => approveAndClose()}
                    variant="primary"
                  >
                    Approve & Close
                  </Button>
                )}
              </div>
            </Modal.Footer>
          ))}
      </Modal>
      <LoanAccountModal
        onHide={() => setOpenAccountModal(false)}
        show={openAccountModal}
        refID={id}
        refetch={refetch}
        loanAmount={loanAmount}
      />
      <ModalLoader show={isLoadingIframe} />
    </div>
  );
}
