import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";

export function WarehouseInventoryNav(props) {
  const { backendUrl } = useAuth();

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const {
    error,
    data = { warehouses: {} },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    ["SINGLE_WAREHOUSE_NAV", { wareshouseLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/single/${wareshouseLocal?.whID}`,
        "GET"
      ),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  console.log(data?.warehouses);

  const items = useMemo(() => {
    return [
      {
        name: "Inventory Entry",
        to: "inventry-entry",
      },
      {
        name: "Item Issuer",
        to: "item-issuer",
      },
      {
        name: "Receive Item",
        to: "receive-item",
      },
      // wareshouseLocal?.cater === "Assets" && {
      //   name: "Maintenance",
      //   to: "maintance",
      // },
      wareshouseLocal?.cater !== "Assets" && {
        name: "Plant Damage Recorder",
        to: "plant-damages",
      },
      wareshouseLocal?.cater !== "Assets" && {
        name: "Damage/Return Recorder",
        to: "damages-return-recorder",
      },
      {
        name: "Purchase History",
        to: "purchase-history",
      },
      data?.warehouses?.Warehouse_Type === "Storage" && {
        name: "Issue History",
        to: "issue-history",
      },
      data?.warehouses?.Warehouse_Type === "Storage" && {
        name: "Issue History",
        to: "return-history",
      },
    ];
  }, [wareshouseLocal?.cater, data?.warehouses]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {Object.entries(item).length > 0 && (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
