import { Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import { Fragment, useState, useEffect, useMemo } from "react";
import quicklinks from "./QuickLinks.json";
import {
  Customers,
  Accounts,
  Financialreports,
  Formapproval,
  Inventory,
  Invoice,
  Payments,
  Vendors,
  Warehouse,
  Collaboration,
} from "./Icons";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Cookies from "universal-cookie";

function IconResolver(name) {
  switch (name) {
    case "Customers":
      return <Customers />;
      break;
    case "Accounts":
      return <Accounts />;
      break;
    case "Financialreports":
      return <Financialreports />;
    case "Formapproval":
      return <Formapproval />;
    case "Inventory":
      return <Inventory />;
    case "Invoice":
      return <Invoice />;
    case "Payments":
      return <Payments />;
    case "Procurement":
      return <Vendors />;
    case "Warehouse":
      return <Warehouse />;
    case "Collaboration":
      return <Collaboration />;
    default:
    // code block
  }
}

export default function QuickLinkComponent({
  items,
  isIronRod,
  isAdmin,
  isCashier,
  isCement,
  isTransportAndLogistics,
  isGigc,
  Item_Type,
  enableDrumAccount,
}) {
  const cookies = new Cookies();
  const [search, setSearch] = useState("");
  const [showLinks, setShowLinks] = useState(true);
  const [app, setApp] = useState(
    `${process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN}/api/login?t=${cookies.get(
      "fextoken"
    )}&redirect=${process.env.REACT_APP_FEXSPACE_APP_DOMAIN}`
  );
  const [drive, setDrive] = useState(
    `${process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN}/api/login?t=${cookies.get(
      "fextoken"
    )}&redirect=${process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN}`
  );
  const [collaboration, setCollaboration] = useState(
    `${process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN}/api/login?t=${cookies.get(
      "fextoken"
    )}&redirect=${process.env.REACT_APP_FEXSPACE_COLLABORATION_DOMAIN}`
  );

  const filteredLinks = useMemo(() => {
    if (!search) {
      return quicklinks;
    }

    let links = quicklinks.map((el) => {
      return {
        ...el,
        child: el.child
          .map((childEl) => {
            return {
              ...childEl,
              // name: childEl.name.filter(name => name.toLowerCase().includes(search.toLowerCase())),
              routes: childEl.routes.filter((routes) =>
                routes.name.toLowerCase().includes(search.toLowerCase())
              ),
            };
          })
          .filter((child) => child.routes.length),
      };
    });

    return links;
  }, [search]);

  return (
    <>
      <Dropdown.Menu
        popperConfig={{
          strategy: "fixed",
        }}
        renderOnMount
        className="quicklinks"
      >
        <input
          type={"text"}
          placeholder={"Type your search term"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {showLinks ? (
          <>
            <div
              className={"quick-links"}
              onClick={() => {
                setSearch("");
              }}
            >
              {filteredLinks.map((el, index) => (
                <Fragment key={index}>
                  <div className={"child"}>
                    {el.child.map((child, i) => (
                      <>
                        <div className={"quick-link-routes"}>
                          <h6 key={i}>
                            <span className={"link-icon"}>
                              {IconResolver(child.icon)}
                            </span>
                            <span>{child.name}</span>
                          </h6>
                          <ul>
                            {child.routes.map((c, d) => (
                              <Dropdown.Item as="li" key={d}>
                                {c.external != undefined && c.external ? (
                                  <a
                                    href={`${
                                      c.type == "app"
                                        ? app + c.link
                                        : c.type == "drive"
                                        ? drive
                                        : collaboration + c.link
                                    }`}
                                  >
                                    {c.name}
                                  </a>
                                ) : c.external != undefined && !c.external ? (
                                  <span className={"external-link"}>
                                    {c.name}
                                  </span>
                                ) : (
                                  <Link to={`${c.link}`}>{c.name}</Link>
                                )}
                              </Dropdown.Item>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
            <div
              className={"mobile-quick-links"}
              onClick={() => {
                setSearch("");
              }}
            >
              {filteredLinks.map((el, index) => (
                <Fragment key={index}>
                  <div className={"child"}>
                    {el.child.map((child, i) => (
                      <>
                        <div className={"quick-link-routes"}>
                          <h6 key={i}>
                            <span className={"link-icon"}>
                              {IconResolver(child.icon)}
                            </span>
                            <span>{child.name}</span>
                          </h6>
                          <ul>
                            {child.routes.map((c, d) => (
                              <Dropdown.Item as="li" key={d}>
                                {c.external != undefined && c.external ? (
                                  <a
                                    href={`${
                                      c.type == "app"
                                        ? app + c.link
                                        : c.type == "drive"
                                        ? drive
                                        : collaboration + c.link
                                    }`}
                                  >
                                    {c.name}
                                  </a>
                                ) : c.external != undefined && !c.external ? (
                                  <span className={"external-link"}>
                                    {c.name}
                                  </span>
                                ) : (
                                  <Link to={`${c.link}`}>{c.name}</Link>
                                )}
                              </Dropdown.Item>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </>
        ) : (
          <div
            className={"quick-links"}
            onClick={() => {
              setSearch("");
            }}
          >
            <div className={"child"}>
              <p>
                Result not found, use{" "}
                <span className={"external-link"}>Universal Search</span>
              </p>
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </>
  );
}
